import {ApplicationConfig} from '@angular/core'
import {provideRouter, withInMemoryScrolling} from '@angular/router'
import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http'
import {provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser'
import {provideAnimations} from '@angular/platform-browser/animations'

import {routes} from './app.routes'
import {requestInterceptor} from '@interceptors/request-interceptor'

declare global {
  interface Window {
    Masonry: any
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({anchorScrolling: 'enabled'}),
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([requestInterceptor]),
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({includePostRequests: false}),
    ),
    provideAnimations(),
  ],
}
