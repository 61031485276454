import {Component} from '@angular/core'
import {NgClass} from '@angular/common'

import {AlertService} from '@services/alert.service'

@Component({
  standalone: true,
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  imports: [
    NgClass,
  ],
})
export class AlertsComponent {

  constructor(
    public alertService: AlertService,
  ) {
  }
}
