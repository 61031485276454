@for (alert of alertService.alerts; track alert.id) {
	<div
		aria-live="assertive"
		class="alert-box pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
		[class.hiding]="alert.hiding"
	>
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<div
				class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
				[class.bg-green-50]="alert.type === 'primary'"
				[class.bg-red-50]="alert.type === 'danger'"
			>
				<div class="p-4">
					<div class="flex items-start">
						<div class="flex-shrink-0">
							@switch (alert.type) {
								@case ('primary') {
									<svg class="size-6 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
									</svg>
								}
								@case ('danger') {
									<svg class="size-6 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
										<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
									</svg>
								}
							}
						</div>
						<div class="ml-3 w-0 flex-1 pt-0.5">
							<p
								class="text-sm font-medium"
								[class.text-green-800]="alert.type === 'primary'"
								[class.text-red-800]="alert.type === 'danger'"
							>
								{{ alert.message }}
							</p>
						</div>
						<div class="ml-4 flex flex-shrink-0">
							<button
								(click)="alertService.hide(alert.id)"
								class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
								[ngClass]="{
									'text-green-500 hover:bg-green-100 focus:ring-green-600': alert.type === 'primary',
									'text-red-500 hover:bg-red-100 focus:ring-red-600': alert.type === 'danger',
								}"
							>
								<svg class="size-6" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}
