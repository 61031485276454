import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'

import {AlertsComponent} from '@components/alerts/alerts.component'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AlertsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
}
